import React, { useEffect, useState } from 'react'
import { Card, Statistic, Typography } from 'antd';
import { GetAPI } from '../../../redux';
import { useDispatch } from 'react-redux';
import { RedoOutlined } from '@ant-design/icons';
import LogMessage from '../../dashboard/pages/logs/message/error';
import { BaseColor } from '../../../config/env';

export default () => {
    const dispatch = useDispatch()
    const [datamonit, setDataMonit] = useState({
        "export": {
            "success": 0,
            "error": 0
        },
        "import": {
            "success": 0,
            "error": 0
        },
        "distribusi": {
            "success": 0,
            "error": 0
        }
    })

    const getMonit = (value) => {
        dispatch(GetAPI({ url: 'api/v1/monitoring/display', data: value }))
            .then(resp => {
                setDataMonit(resp?.payload?.data)
            })
    }

    useEffect(() => {
        getMonit()
    }, [])

    const DashboardCard = ({ title, successCount, errorCount, color }) => (
        <Card style={{ width: 300, margin: '20px', backgroundColor: BaseColor }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                    <h3 style={{ color: "white" }}>{title}</h3>
                    <div>
                        <Statistic title={<span style={{ color: "white" }}>Success</span>} value={successCount} valueStyle={{ color: 'green' }} />
                    </div>
                    <div>
                        <Statistic title={<span style={{ color: "white" }}>Error</span>} value={errorCount} valueStyle={{ color: 'red' }} />
                    </div>
                </div>
                <div style={{ fontSize: '2em', color }}>
                    <Statistic title={<span style={{ color: "white" }}>Total</span>} value={successCount + errorCount} valueStyle={{ color: 'white' }} />
                </div>
            </div>
        </Card>
    );
    return (
        <>
            <Typography.Title level={5}>Display Monitoring</Typography.Title>
            <RedoOutlined onClick={() => getMonit()} />
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                <DashboardCard title="Export" successCount={datamonit?.export?.success} errorCount={datamonit?.export?.error} color="green" />
                <DashboardCard title="Import" successCount={datamonit?.import?.success} errorCount={datamonit?.import?.error} color="blue" />
                <DashboardCard title="Distribusi" successCount={datamonit?.distribusi?.success} errorCount={datamonit?.distribusi?.error} color="yellow" />
            </div>
            {/* <br />
            <br /> */}
            {/* <LogMessage /> */}
        </>
    )
}