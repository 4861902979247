import React from 'react'
import TablePage from '../../../../../components/pages/TablePage'
import moment from 'moment'

export default () => {
    const columns = [
        {
            selector: (row) => row?.awb_number,
            name: 'Master AWB Number',
            wrap: true,
        },
        {
            selector: (row) => row?.hawb_number,
            name: 'HAWB Number',
            wrap: true,
        },
        {
            selector: (row) => row?.log,
            name: 'log',
            wrap: true,
        },
        {
            selector: (row) => (row?.createdAt && moment(row.createdAt).format('DD-MMM-YYYY HH:mm')) || '-',
            name: "CreatedAt",
            id: 'createdAt'
        }
    ]
    return (
        <TablePage
            title={"Activity Logs"}
            url="api/v1/tps_activity"
            actionPosition={'first'}
            columns={columns}
        />
    )
}