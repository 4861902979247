import React, { useState } from 'react';
import ExcelJS from 'exceljs';
import { message, Tooltip } from 'antd';
import TablePage from '../../../../../components/pages/TablePage'
import moment from 'moment';
import DetailPage from './detail';
import { RiFilePptLine, RiFileTransferLine } from "react-icons/ri";
import { HiOutlineDownload } from "react-icons/hi";
import { useDispatch } from 'react-redux';
import { PostAPI } from '../../../../../redux';

export default () => {

    const generateExcel = async (data) => {
        const { flight_number, flight_date_str, manifest } = data;
        const workbook = new ExcelJS.Workbook();

        let hawbs = [];

        const masterAWBData = manifest.map(item => {
            hawbs = [...hawbs, ...item?.hawbs]
            return {
                awb_number: item.awb_id?.awb_number,
                flight_number: flight_number,
                flight_date_str: flight_date_str,
                origin: item.awb_id?.origin?.code,
                dest: item.awb_id?.dest?.code,
                uld: item.uld,
                packet_desc: item.packet_desc,
                pieces: item.pieces,
                weight: item.weight,
                volume: item.volume,
                agent: item.awb_id?.agent?.name,
                shipper: item.awb_id?.shipper?.name,
                consigne: item.awb_id?.consigne?.name,
            };
        });

        const masterSheet = workbook.addWorksheet('Master AWB');

        masterSheet.columns = [
            { header: 'awb number', key: 'awb_number' },
            { header: 'flight number', key: 'flight_number' },
            { header: 'flight date', key: 'flight_date_str' },
            { header: 'origin', key: 'origin' },
            { header: 'dest', key: 'dest' },
            { header: 'uld', key: 'uld' },
            { header: 'packet desc', key: 'packet_desc' },
            { header: 'pieces', key: 'pieces' },
            { header: 'weight', key: 'weight' },
            { header: 'volume', key: 'volume' },
            { header: 'agent', key: 'agent' },
            { header: 'shipper', key: 'shipper' },
            { header: 'consigne', key: 'consigne' },
        ];

        masterSheet.addRows(masterAWBData);

        const houseAWBData = hawbs.map(item => {
            return {
                awb_number: item.master_number,
                house_number: item.house_number,
                origin: item.origin,
                dest: item.dest,
                pieces: item.pieces,
                weight: item.weight,
                shipper: item.shipper,
                consigne: item.consigne
            };
        });

        const houseSheet = workbook.addWorksheet('House AWB');

        houseSheet.columns = [
            { header: 'awb number', key: 'awb_number' },
            { header: 'house number', key: 'house_number' },
            { header: 'origin', key: 'origin' },
            { header: 'dest', key: 'dest' },
            { header: 'weight', key: 'weight' },
            { header: 'pieces', key: 'pieces' },
            { header: 'shipper', key: 'shipper' },
            { header: 'consigne', key: 'consigne' },
        ];

        houseSheet.addRows(houseAWBData);

        const buffer = await workbook.xlsx.writeBuffer();

        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `manifest ${flight_number}-${flight_date_str}.xlsx`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    const columns = [
        {
            selector: (row) => {
                return (
                    <>
                        {row?.awb_exists === false &&
                            <Tooltip title="add awb tps">
                                <RiFileTransferLine size={20} onClick={() => handleCreated(row)} />
                            </Tooltip>
                        }
                        <Tooltip title="download excel">
                            <HiOutlineDownload style={{ marginLeft: 20 }} size={20} onClick={() => generateExcel(row)} />
                        </Tooltip>

                        {row?.rskp_exists === false &&
                            <Tooltip title="create rksp">
                                <RiFilePptLine style={{ marginLeft: 20 }} size={20} onClick={() => handleCreatedRksp(row?._id)} />
                            </Tooltip>
                        }
                    </>
                )
            },
            name: "Create AWB",
            id: 'created_at'
        },
        {
            selector: ({ airline_str }) => airline_str,
            name: "Airline",
            id: 'airline_str'
        },
        {
            selector: ({ flight_number }) => flight_number,
            name: "Flight Number",
            id: 'flight_number'
        },
        {
            selector: ({ acreg }) => acreg,
            name: "Registration",
            id: 'acreg'
        },
        {
            selector: ({ flight_date_str }) => flight_date_str,
            name: "Flight Date",
            id: 'flight_date_str'
        },
        {
            selector: ({ route }) => route,
            name: "Route",
            id: 'route'
        },
        {
            selector: ({ total }) => total,
            name: "Koli",
            id: 'total'
        },
        {
            selector: ({ weight }) => weight,
            name: "Kilo",
            id: 'weight'
        },
        {
            selector: ({ volume }) => volume,
            name: "Volume",
            id: 'volume'
        },
        {
            selector: (row) => (row?.createdAt && moment(row.createdAt).format('DD-MMM-YYYY HH:mm:ss')) || '-',
            name: "CreatedAt",
            id: 'created_at'
        }
    ]

    const dispatch = useDispatch();
    const [refresh, setRefresh] = useState(moment().unix())

    const flightNumberSlice = (code) => {
        if (code.length === 5) {
            return code.slice(0, 2) + '0' + code.slice(2);
        } else if (code.length > 5) {
            return code;
        } else {
            return code;
        }
    }

    const handleCreated = (value) => {
        const { flight_number, flight_date_str, manifest, _id } = value;

        const r = manifest.map(
            ({
                awb_id,
                createdAt,
                pieces,
                weight,
                hawbs
            }) => {
                return {
                    mawb_number: awb_id?.awb_number,
                    hawb_number: awb_id?.awb_number,
                    mawb_date: moment(createdAt).format('YYYYMMDD'),
                    hawb_date: moment(createdAt).format('YYYYMMDD'),
                    bruto: weight,
                    jml_kemas: pieces,
                    hawbs
                }
            })
        const data = {
            flight_id: _id,
            flight_number: flightNumberSlice(flight_number),
            flight_date: moment(flight_date_str).format('DD-MMM-YYYY'),
            no_bc11: "0",
            tgl_bc11: moment().format('YYYYMMDD'),
            awbs: r,
        }

        const url = `api/v1/import_awb/add`;
        dispatch(PostAPI({ url, data })).then(resp => {
            setRefresh(moment().unix())
            message.success('create awb success');
        })

        return true;
    }

    const handleCreatedRksp = (id) => {
        const url = `api/v1/bc_params/create_rskp/${id}`
        const data = {};
        dispatch(PostAPI({ url, data })).then(resp => {
            setRefresh(moment().unix())
        })
    }

    return (
        <TablePage
            title={"Domestic Flight Manifest"}
            url="api/v1/flight_number/manifest/domestic"
            columns={columns}
            ExpandComponent={DetailPage}
            refresh={refresh}
        />
    )
}