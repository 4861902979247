import React, { useState } from 'react';
import TablePage from '../../../../../components/pages/TablePage';
import DetailPage from './detail';
import moment from 'moment';
import { RiDvdFill } from "react-icons/ri";
import { useDispatch } from 'react-redux';
import { PostAPI } from '../../../../../redux';

export default () => {

    const dispatch = useDispatch();
    const [refresh, setRefresh] = useState(moment().unix())

    const handleResend = (data) => {
        const url = 'api/v1/tps_logs/resend'
        dispatch(PostAPI({ url, data })).then(resp => {
            setRefresh(moment().unix())
        })
    }

    const columns = [
        {
            selector: (row) => row?.msgId,
            name: 'Msg Id',
            wrap: true,
        },
        {
            selector: (row) => row?.method,
            name: 'Method',
            wrap: true,
        },
        {
            selector: (row) => row?.result,
            name: 'Result',
            wrap: true,
        },
        {
            selector: (row) => (row?.createdAt && moment(row.createdAt).format('DD-MMM-YYYY HH:mm')) || '-',
            name: "CreatedAt",
            id: 'createdAt'
        }
    ]

    return (
        <TablePage
            title={"Message Logs"}
            url="api/v1/tps_logs"
            columns={columns}
            ExpandComponent={DetailPage}
            refresh={refresh}
        />
    )
}