import { Form, Input } from 'antd';
import React, { useMemo, useState } from 'react'
import FormPage from '../../../../../components/pages/FormPage'

const DefModel = {
    code: '',
    icao: '',
    name: '',
    country: '',
    prefix: '',
    idModule: '',
    userModule: '',
    npwp: '',
    no_aju: '',
    increment_no_aju: 0,
    airport_id: null
}

export default () => {
    const [model, setModel] = useState(DefModel);
    return (
        <FormPage
            url={'api/v1/airlines'}
            formValid={false}
            model={model}
            setModel={setModel}
            wrapperCol={{ span: 8 }}
            labelCol={{ span: 3 }}
            title={"Airline"}
            emptyModel={DefModel}
            callbackPath={"/master/airline"}
        >
            <Form.Item name={'code'} label="IATA Code" rules={[{ required: true }]} >
                <Input autoFocus />
            </Form.Item>
            <Form.Item name={'icao'} label="ICAO Code" >
                <Input />
            </Form.Item>
            <Form.Item name={'name'} label="Name" rules={[{ required: true }]}>
                <Input />
            </Form.Item>
            <Form.Item name={'prefix'} label="Prefix">
                <Input />
            </Form.Item>
            <Form.Item name={'country'} label="Country" rules={[{ required: true }]}>
                <Input />
            </Form.Item>
            <Form.Item name={'npwp'} label="npwp" rules={[{ required: true }]}>
                <Input />
            </Form.Item>
            <Form.Item name={'no_aju'} label="No Aju">
                <Input />
            </Form.Item>
            <Form.Item name={'increment_no_aju'} label="Increment No Aju">
                <Input type='number' />
            </Form.Item>
        </FormPage>
    )
}